import React, { useEffect, useRef, useState } from 'react';
import '../styles/style.css';
import {
  Building2,
  Briefcase,
  Lightbulb,
  Building,
  Server,
  ShieldCheck,
  Activity,
  Smartphone
} from 'lucide-react';

const HomePage = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const whoWeAreRef = useRef(null);
    const hexRef = useRef({});
    const achievementsRef = useRef(null);
    const clientsRef = useRef(null);
    const sectionsRef = useRef([]);
    const sectionRef = useRef(null);
    const [inView, setInView] = useState(false);
    const techRef = useRef(null);
    const [menuOpen, setMenuOpen] = useState(false);


    const industries = [
      {
        title: "Commercial",
        description: "Custom solutions for office and workspace maintenance.",
        icon: Building2
      },
      {
        title: "Industrial",
        description: "Heavy-duty cleaning tailored to your facility.",
        icon: Server
      },
      {
        title: "Education",
        description: "Safe and hygienic environments for students and staff.",
        icon: Lightbulb
      },
      {
        title: "Healthcare",
        description: "Sanitation services that meet strict health standards.",
        icon: ShieldCheck
      },
      {
        title: "Labour Hire",
        description: "Flexible workforce support when and where you need it.",
        icon: Briefcase
      }
    ];
        

  useEffect(() => {
    const timeout = setTimeout(() => {
      const loadingScreen = document.getElementById("loading-screen");
      if (loadingScreen) {
        loadingScreen.classList.add("fade-out");
        setTimeout(() => loadingScreen.classList.add("hidden"), 800);
      }
    }, 1500);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    document.body.style.overflow = menuOpen ? 'hidden' : 'auto';
  }, [menuOpen]);  

  useEffect(() => {
    const slides = document.querySelectorAll(".slider img");
    const interval = setInterval(() => {
      slides.forEach((slide, index) => {
        slide.classList.remove("active");
        if (index === (currentSlide + 1) % slides.length) {
          slide.classList.add("active");
        }
      });
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [currentSlide]);

  useEffect(() => {
    const section = whoWeAreRef.current;
    const svg = document.querySelector(".who-we-are-svg");
    const textElements = section.querySelectorAll(".who-we-are-text p, .who-we-are-text h2");
    const bulletPoints = section.querySelectorAll(".who-we-are-list li");
    const ctaButton = section.querySelector(".cta-button");
  
    hexRef.current.top = svg.querySelector(".slide-top");
    hexRef.current.left = svg.querySelector(".slide-left");
    hexRef.current.right = svg.querySelector(".slide-right");
    hexRef.current.bottom = svg.querySelector(".slide-bottom");
  
    svg.style.opacity = "0";
    Object.entries(hexRef.current).forEach(([key, el]) => {
      if (!el) return;
      el.style.opacity = "0";
      if (key === "top" || key === "bottom") {
        el.style.transform = key === "top" ? "translateY(-100px)" : "translateY(100px)";
      } else {
        el.style.transform = key === "left" ? "translateX(-100px)" : "translateX(100px)";
      }
    });
  
    function handleScrollReveal() {
      const rect = section.getBoundingClientRect();
      const windowHeight = window.innerHeight;
  
      if (rect.top < windowHeight * 0.6) {
        section.classList.add("visible");
        svg.style.opacity = "1";
  
        setTimeout(() => {
          hexRef.current.top.style.opacity = "1";
          hexRef.current.top.style.transform = "translateY(0)";
        }, 200);
        setTimeout(() => {
          hexRef.current.left.style.opacity = "1";
          hexRef.current.left.style.transform = "translateX(0)";
        }, 400);
        setTimeout(() => {
          hexRef.current.right.style.opacity = "1";
          hexRef.current.right.style.transform = "translateX(0)";
        }, 600);
        setTimeout(() => {
          hexRef.current.bottom.style.opacity = "1";
          hexRef.current.bottom.style.transform = "translateY(0)";
        }, 800);
  
        textElements.forEach((el, i) => {
          setTimeout(() => el.classList.add("show"), i * 300);
        });
  
        bulletPoints.forEach((el, i) => {
          setTimeout(() => el.classList.add("show"), 1000 + i * 200);
        });
  
        setTimeout(() => {
          if (ctaButton) ctaButton.classList.add("show");
        }, 2000);
  
        window.removeEventListener("scroll", handleScrollReveal);
      }
    }
  
    window.addEventListener("scroll", handleScrollReveal);
    handleScrollReveal();
  
    return () => window.removeEventListener("scroll", handleScrollReveal);
  }, []);


    useEffect(() => {
    const section = achievementsRef.current;
    const counters = section?.querySelectorAll('.count');

    const startCounting = () => {
        counters?.forEach(counter => {
        const target = +counter.getAttribute('data-target');
        const increment = target / 100;
        let count = 0;

        const updateCount = () => {
            count += increment;
            if (count < target) {
            counter.innerText = Math.floor(count);
            setTimeout(updateCount, 15);
            } else {
            counter.innerText = target;
            }
        };
        updateCount();
        });
    };

    if ('IntersectionObserver' in window) {
        const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
            section.classList.add('animate');
            startCounting();
            observer.unobserve(section);
            }
        });
        }, { threshold: 0.5 });

        if (section) observer.observe(section);
        return () => observer.disconnect();
    } else {
        section.classList.add('animate');
        startCounting();
    }
    }, []);

    useEffect(() => {
        const section = clientsRef.current;
        const logos = section.querySelectorAll(".client-logo");
      
        const handleScroll = () => {
          const rect = section.getBoundingClientRect();
          const triggerPoint = window.innerHeight * 0.5;
      
          if (rect.top < triggerPoint) {
            section.classList.add("animate");
      
            logos.forEach((logo, index) => {
              setTimeout(() => {
                logo.classList.add("show");
              }, index * 150);
            });
      
            window.removeEventListener("scroll", handleScroll);
          }
        };
      
        window.addEventListener("scroll", handleScroll);
        handleScroll();
      
        return () => window.removeEventListener("scroll", handleScroll);
      }, []);

      // Animate Tech Section upon view
      useEffect(() => {
        const section = techRef.current;
        const items = section.querySelectorAll(".slide-in-tech");
      
        function revealTech() {
          const rect = section.getBoundingClientRect();
          const triggerPoint = window.innerHeight * 0.6;
      
          if (rect.top < triggerPoint) {
            items.forEach((el, index) => {
              setTimeout(() => {
                el.classList.add("show");
              }, index * 200);
            });
      
            window.removeEventListener("scroll", revealTech);
          }
        }
      
        window.addEventListener("scroll", revealTech);
        revealTech();
      
        return () => window.removeEventListener("scroll", revealTech);
      }, []);
      


      const useInView = (threshold = 0.3) => {
        const ref = useRef();
        const [isVisible, setVisible] = useState(false);
      
        useEffect(() => {
          const observer = new IntersectionObserver(
            ([entry]) => entry.isIntersecting && setVisible(true),
            { threshold }
          );
      
          if (ref.current) observer.observe(ref.current);
          return () => observer.disconnect();
        }, [threshold]);
      
        return [ref, isVisible];
      };



      // Industries Section
      useEffect(() => {
        const handleScroll = () => {
          const section = document.querySelector('.industries-section');
          if (!section) return;
      
          const scrollX = window.scrollY * 0.2;
          section.style.setProperty('--scrollX', `${scrollX}px`);
        };
      
        // Attach event
        window.addEventListener('scroll', handleScroll);
      
        // Schedule after paint to ensure DOM is ready
        requestAnimationFrame(() => handleScroll());
      
        // Clean up
        return () => window.removeEventListener('scroll', handleScroll);
      }, []);
      
      // Bubbles Effect
      const generateBubbles = () => {
        return Array.from({ length: 3 }).map((_, index) => {
          const size = Math.floor(Math.random() * 10) + 8;
          const left = Math.random() * 80;
          const top = Math.random() * 60;
          const duration = (Math.random() * 3 + 4).toFixed(1);
          return (
            <div
              key={index}
              className="bubble"
              style={{
                width: `${size}px`,
                height: `${size}px`,
                left: `${left}%`,
                top: `${top}%`,
                animationDuration: `${duration}s`,
              }}
            ></div>
          );
        });
      };
      
              
  return (

    <div>
{/* LOADING SCREEN */}
      <div id="loading-screen">
        <div className="loading-container">
          <div className="logo"></div>
        </div>
      </div>

{/* NAVBAR SECTION */}
      <header className="navbar">
        {/* Logo */}
        <a href="/" className="navbar-logo">
          <img src="/images/logo.png" alt="Adept Logo" />
        </a>

        {/* Hamburger (Only shown when mobile menu is closed) */}
        {!menuOpen && (
          <button className="hamburger" onClick={() => setMenuOpen(true)} aria-label="Open Menu">
            <span></span>
            <span></span>
            <span></span>
          </button>
        )}

        {/* Desktop Navigation */}
        <nav className="nav-links">
          <div className="dropdown">
            <a href="#" className="dropbtn">Services</a>
            <div className="dropdown-content">
              <a href="#">Offices</a>
              <a href="#">Industrial</a>
              <a href="#">Health</a>
            </div>
          </div>
          <a href="#">Careers</a>
          <a href="#">About</a>
          <a href="#">Contact</a>
          <a
            href="https://www.linkedin.com/company/adept-services-pty-ltd/"
            target="_blank"
            rel="noopener noreferrer"
            className="linkedin-button"
          >
            <img src="/images/linkedin_logo_initials.png" alt="LinkedIn" />
          </a>
          <a href="#quote" className="navbar-cta-button">Get Quote</a>
        </nav>

        {/* Mobile Menu Overlay */}
        {menuOpen && (
          <div className="mobile-menu-overlay">
            <button className="close-btn" onClick={() => setMenuOpen(false)} aria-label="Close Menu">✕</button>
            <nav className="mobile-menu-nav">
              <a href="#">Services</a>
              <a href="#">Careers</a>
              <a href="#">About</a>
              <a href="#">Contact</a>
              <a
                href="https://www.linkedin.com/company/adept-services-pty-ltd/"
                target="_blank"
                rel="noopener noreferrer"
                className="linkedin-button"
              >
                <img src="/images/linkedin_logo_initials.png" alt="LinkedIn" className="linkedin-icon" />
              </a>
              <a href="#quote" className="navbar-cta-button">Get Quote</a>
            </nav>
          </div>
        )}
      </header>






{/* HERO SECTION */}
      <div className="hero">
        <div className="slider">
          <img src="/images/hero2.jpg" alt="Hero Image 1" className="active" />
          <img src="/images/hero3.jpg" alt="Hero Image 2" />
          <img src="/images/hero4.jpg" alt="Hero Image 3" />
        </div>
        <div className="text-overlay">
          <h1>Your Trusted Commercial Cleaning Partner</h1>
          <p>At Adept Services, we provide top-notch commercial cleaning solutions tailored to meet your business needs.</p>
          <a href="#quote" className="cta-button">Contact Us</a>
        </div>
      </div>

{/* WHO WE ARE SECTION */}
      <section className="who-we-are-section" ref={whoWeAreRef}>
        <div className="who-we-are-text">
          <h2 className="slide-in slide-in-1">Who We Are</h2>
          <p className="slide-in slide-in-2">We are dedicated professionals providing cleaning services to businesses, employees, and environments.</p>
          <p className="slide-in slide-in-3">Our mission is to exceed expectations with attention to detail, efficient processes, and reliable service, letting you focus on what matters.</p>
          <p className="slide-in slide-in-4">For high-performance industrial cleaning, trust Adept Services. Contact us today to discuss your facility’s needs.</p>
          <ul className="who-we-are-list">
            <li><strong>Expert Knowledge</strong>: Delivering exceptional cleaning results.</li>
            <li><strong>Quality Assurance</strong>: Ensuring top-tier outcomes.</li>
            <li><strong>Health & Safety</strong>: Prioritizing safe, risk-free solutions.</li>
            <li><strong>Sustainability</strong>: Leading with eco-friendly practices.</li>
            <li><strong>Contract Excellence</strong>: Managing seamless service delivery.</li>
          </ul>
          <a href="#learn-more" className="cta-button">Learn More</a>
        </div>

        <div className="who-we-are-svg">
          <svg width="100%" height="100%" viewBox="0 0 1845 2055" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
              <pattern id="hexImage" patternUnits="objectBoundingBox" width="1" height="1">
                <image xlinkHref="/images/a_adept.png" width="9%" height="9%" x="4.5%" y=".5%" preserveAspectRatio="xMidYMid meet" />
              </pattern>
            </defs>
            <g transform="matrix(4.16667,0,0,4.16667,0,0)">
              <g className="slide-left" style={{ opacity: 0, transform: 'translateX(-100px)' }}>
                <g transform="matrix(2.16316,0,0,-2.16316,216.951,188.455)">
                  <path d="M0,-134.541C-8.972,-134.541 -17.331,-129.715 -21.813,-121.946L-46.109,-79.866C-50.594,-72.097 -50.594,-62.445 -46.109,-54.676L-21.813,-12.596C-17.331,-4.827 -8.972,0 0,0L48.591,0C57.563,0 65.921,-4.827 70.404,-12.596L94.7,-54.676C99.187,-62.445 99.187,-72.097 94.702,-79.866L70.404,-121.946C65.921,-129.715 57.563,-134.541 48.591,-134.541L0,-134.541ZM48.591,6.318L0,6.318C-11.222,6.318 -21.677,0.281 -27.288,-9.437L-51.583,-51.517C-57.191,-61.234 -57.191,-73.308 -51.583,-83.025L-27.288,-125.105C-21.677,-134.823 -11.222,-140.859 0,-140.859L48.591,-140.859C59.813,-140.859 70.268,-134.823 75.878,-125.105L100.173,-83.025C105.783,-73.308 105.783,-61.234 100.171,-51.517L75.878,-9.437C70.268,0.281 59.813,6.318 48.591,6.318" style={{ fill: "white" }} />
                </g>
              </g>
              <g className="slide-top" style={{ opacity: 0, transform: 'translateY(-100px)' }}>
                <path d="M216.951,188.455L322.062,188.455C342.348,189.352 358.331,197.891 369.247,215.702L421.803,306.728C431.057,323.844 431.934,341.908 421.807,361.218L369.247,452.244C358.773,469.66 342.892,478.499 322.062,479.489L216.951,479.489C196.444,478.793 180.583,469.944 169.766,452.244L117.21,361.218C107.212,342.349 107.781,324.225 117.21,306.728L169.766,215.702C179.857,199.488 194.719,189.309 216.951,188.455" style={{ fill: "url(#hexImage)" }} />
              </g>
              <g className="slide-right" style={{ opacity: 0, transform: 'translateX(100px)' }}>
                <g transform="matrix(1.82713,0,0,-1.82713,70.1669,969.515)">
                  <g opacity="0.3">
                    <g transform="translate(14.2413,356.288)">
                      <path d="M0,140.859L48.591,140.859C58.719,140.859 68.078,135.456 73.142,126.684L97.437,84.604C102.501,75.833 102.501,65.026 97.437,56.255L73.142,14.174C68.078,5.403 58.719,0 48.591,0L0,0C-10.128,0 -19.487,5.403 -24.551,14.174L-48.846,56.255C-53.91,65.026 -53.91,75.833 -48.846,84.604L-24.551,126.684C-19.487,135.456 -10.128,140.859 0,140.859" style={{ fill: "rgb(0,137,122)" }} />
                    </g>
                  </g>
                </g>
              </g>
              <g className="slide-bottom" style={{ opacity: 0, transform: 'translateY(100px)' }}>
                <g transform="matrix(2.43487,0,0,-2.43487,17.5847,1163.21)">
                  <g opacity="0.3">
                    <g transform="translate(105.521,385.025)">
                      <path d="M0,92.706L20.79,92.706C30.917,92.706 40.276,87.303 45.34,78.531L55.735,60.527C60.799,51.756 60.799,40.949 55.735,32.179L45.34,14.174C40.276,5.403 30.917,0 20.79,0L0,0C-10.128,0 -19.487,5.403 -24.551,14.175L-34.945,32.179C-40.009,40.949 -40.009,51.756 -34.945,60.527L-24.551,78.531C-19.487,87.303 -10.128,92.706 0,92.706" style={{ fill: "rgb(21,183,218)" }} />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </section>

{/* ACHIEVEMENTS SECTION */}
        <section className="achievement-section" ref={achievementsRef}>
            <h2>Our Achievements</h2>
            <p>
                By bringing together a diverse range of skills, expertise, and core values, we cultivate a dynamic and results-driven approach that consistently delivers outstanding outcomes.
                Our collective strength enables us to tackle challenges with precision, efficiency, and innovation, making us a trusted and influential force in our industry.
            </p>
            <div className="stats">
                <div className="stat">
                <h3 className="count" data-target="12">0</h3>
                <p><strong>Years Operating</strong></p>
                </div>
                <div className="stat">
                <h3 className="count" data-target="74">0</h3>
                <p><strong>Satisfied Clients</strong></p>
                </div>
                <div className="stat">
                <h3 className="count" data-target="329">0</h3>
                <p><strong>Team Members</strong></p>
                </div>
                <div className="stat">
                <h3 className="count" data-target="653">0</h3>
                <p><strong>Pens Returned to Their Owners</strong></p>
                </div>
            </div>
        </section>

{/* CLIENTS SECTION */}
        <section className="clients-section" ref={clientsRef}>
            <h2>Clients Who Trust Us</h2>
            <p>We proudly serve these industry leaders</p>

            <div className="client-logos">

                {/* Row 1 */}
                <div className="logo-row">
                <div className="client-logo-wrapper aussie-broadband">
                    <img src="/images/logos/AussieBroadband_Logo_Original.png" alt="Aussie Broadband" className="client-logo" />
                </div>
                <div className="client-logo-wrapper leader">
                    <img src="/images/logos/Leader Australia.png" alt="Leader Australia" className="client-logo" />
                </div>
                <div className="client-logo-wrapper">
                    <img src="/images/logos/Lendlease Logo.png" alt="Lendlease" className="client-logo" />
                </div>
                <div className="client-logo-wrapper meriton">
                    <img src="/images/logos/Meriton Logo.png" alt="Meriton" className="client-logo" />
                </div>
                </div>

                {/* Row 2 */}
                <div className="logo-row">
                <div className="client-logo-wrapper pga-academy">
                    <img src="/images/logos/PGA Academy.png" alt="PGA Academy" className="client-logo" />
                </div>
                <div className="client-logo-wrapper plasdene">
                    <img src="/images/logos/Plasdene.png" alt="Plasdene" className="client-logo" />
                </div>
                <div className="client-logo-wrapper">
                    <img src="/images/logos/PPG_Industries-Logo.png" alt="PPG Industries" className="client-logo" />
                </div>
                <div className="client-logo-wrapper">
                    <img src="/images/logos/RSL-LifeCare_Master.png" alt="RSL LifeCare" className="client-logo" />
                </div>
                </div>

                {/* Row 3 - Centered 2 logos */}
                <div className="logo-row last-row">
                <div className="client-logo-spacer" />
                <div className="client-logo-wrapper regis">
                    <img src="/images/logos/Regis Aged Care.png" alt="Regis Aged Care" className="client-logo" />
                </div>
                <div className="client-logo-wrapper symrise">
                    <img src="/images/logos/Symrise.jpg" alt="Symrise" className="client-logo" />
                </div>
                <div className="client-logo-spacer" />
                </div>
            </div>
        </section>

{/* INDUSTRIES SECTION */}
        <div className="industries-section">
          <div className="industries-container">
            <div className="industries-header">
              <h2 className="industries-title">Industries We Serve</h2>
              <p className="industries-description">
                We deliver innovative solutions across diverse sectors, helping businesses transform and thrive in the digital age.
              </p>
            </div>

            <div className="industries-grid">
              {/* Top Row (3 items) */}
              <div className="industries-top-row">
                {industries.slice(0, 3).map(({ title, description, icon: Icon }) => (
                  <div key={title} className="industry-card">
                    {/* Floating Bubbles */}
                    <div className="bubble-wrapper">
                      <div className="bubble" style={{ width: '12px', height: '12px', left: '20%', top: '30%', animationDuration: '5s' }}></div>
                      <div className="bubble" style={{ width: '8px', height: '8px', left: '70%', top: '60%', animationDuration: '4s' }}></div>
                      <div className="bubble" style={{ width: '16px', height: '16px', left: '50%', top: '10%', animationDuration: '6s' }}></div>
                    </div>

                    <div className="industry-icon-wrapper">
                      <Icon className="industry-icon" />
                    </div>
                    <h3 className="industry-type">{title}</h3>
                    <p className="industry-card-description">{description}</p>
                  </div>
                ))}
              </div>

              {/* Bottom Row (2 items) */}
              <div className="industries-bottom-row">
                {industries.slice(3).map(({ title, description, icon: Icon }) => (
                  <div key={title} className="industry-card">
                    {/* Floating Bubbles */}
                    <div className="bubble-wrapper">
                      <div className="bubble"></div>
                      <div className="bubble"></div>
                      <div className="bubble"></div>
                    </div>

                    <div className="industry-icon-wrapper">
                      <Icon className="industry-icon" />
                    </div>
                    <h3 className="industry-type">{title}</h3>
                    <p className="industry-card-description">{description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>







{/* WHY CHOOSE US SECTION */}
      <section className="tech-section" ref={techRef}>
        <div className="tech-container">
          <div className="tech-grid">
            
            {/* LEFT SIDE */}
            <div className="tech-intro">
              <h2 className="tech-title slide-in-tech">Cleaning Meets Innovation</h2>
              <p className="tech-subtitle slide-in-tech">Our process is efficient, dependable, and powered by smart technology to deliver consistent, high-quality cleaning tailored to your site.</p>
              <div className="tech-image-wrapper slide-in-tech">
                <img src="/images/app_image.png" alt="App Preview" className="tech-image" />
              </div>
            </div>

            {/* RIGHT SIDE */}
            <div className="tech-steps">
              {[
                { number: '01.', title: 'Quality Inspection Reporting', desc: 'Our supervisors perform digital inspections to maintain spotless results and track service benchmarks.' },
                { number: '02.', title: 'Time & Attendance Software', desc: 'Staff check-in via mobile apps, so you always know who’s on-site and when.' },
                { number: '03.', title: 'Real-Time Communication Platforms', desc: 'Instant messaging tools connect our teams, ensuring quick issue resolution and updates from field to office.' },
                { number: '04.', title: 'Automated Scheduling & Task Checklists', desc: 'Digital task lists and auto-scheduling ensure nothing is missed — every clean is thorough and accountable.' },
              ].map((step, index) => (
                <div className="tech-step slide-in-tech" key={index}>
                  <span className="tech-step-number">{step.number}</span>
                  <div>
                    <h3 className="tech-step-title">{step.title}</h3>
                    <p className="tech-step-desc">{step.desc}</p>
                  </div>
                </div>
              ))}

              <div className="tech-cta-wrapper slide-in-tech">
                <a href="#contact" className="tech-cta">Get in Touch</a>
              </div>
            </div>
          </div>
        </div>
      </section>















        <footer className="adept-footer">
            <div className="footer-inner">
                {/* LEFT: Footer Content */}
                <div className="footer-main">
                <div className="footer-logo-text">
                    <img src="/images/adept_services_white.png" alt="Adept Logo" className="footer-logo" />
                </div>

                <div className="footer-columns">
                    <div className="footer-col">
                    <h4>About Us</h4>
                    <ul>
                        <li><a href="#">Our Team</a></li>
                        <li><a href="#">Sustainability Approach</a></li>
                        <li><a href="#">Community Engagement</a></li>
                        <li><a href="#">Leadership & Governance</a></li>
                    </ul>
                    </div>

                    <div className="footer-col">
                    <h4>Solutions & Innovation<br />Services</h4>
                    <ul>
                        <li><a href="#">Industries We Service</a></li>
                        <li><a href="#">Our Technology</a></li>
                    </ul>
                    </div>

                    <div className="footer-col">
                    <h4>Connect With Us</h4>
                    <ul>
                        <li><a href="#">Enquiry Form</a></li>
                        <li><a href="#">Locations & Reach</a></li>
                    </ul>
                    </div>

                    <div className="footer-col">
                    <h4>Careers at Adept</h4>
                    <ul>
                        <li><a href="#">Worklife at Adept</a></li>
                        <li><a href="#">Open Roles</a></li>
                        <li><a href="#">Recruitment FAQs</a></li>
                    </ul>
                    </div>
                </div>

                <div className="footer-tagline">
                    <h3>Adept Solutions,<br />Limitless Possibilities</h3>
                    <div className="footer-meta">
                    <a href="https://linkedin.com" className="linkedin-icon" target="_blank" rel="noopener noreferrer">in</a>
                    <p>
                        © {new Date().getFullYear()} Adept Services Pty Ltd<br />
                        ABN 81 628 366 183 | <a href="#">Privacy</a> | <a href="#">Site Map</a>
                    </p>
                    </div>
                    <p className="footer-disclaimer">
                    The content on this website is the property of Adept Services Pty Ltd. All rights reserved.
                    </p>
                </div>
                </div>

                {/* RIGHT: Large Brand Icon */}
                <div className="footer-brand-icon">
                <img src="/images/a_adept.png" alt="Adept Big Logo" />
                </div>
            </div>
        </footer>



    </div>
  );
};

export default HomePage;
